import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	return data;
};

const frontendToBackendFormatter = originalData => {
	let leave = JSON.parse(JSON.stringify(originalData));
	delete leave.id
	return leave;
};

export default {
    state() {
        return {
			tableHeaders: ["id", "name"],
			tableData: [
				{
					id: 232389,
					name: "Sinan",
					leave_date: "Dec.15.21-Dec.17.21",
					subjectLikeToTeach: "English reading,English writing",
					standard: "2nd standard",
					age: "5",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
				{
					id: 232390,
					name: "Jabir",
					leave_date: "Dec.15.21-Dec.17.21",
					subjectLikeToTeach: "English reading,English writing",
					standard: "2nd standard",
					age: "5",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
				{
					id: 232391,
					name: "Rahul",
					leave_date: "Dec.15.21-Dec.17.21",
					subjectLikeToTeach: "English reading,English writing",
					standard: "2nd standard",
					age: "5",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
				{
					id: 232392,
					name: "Keerthi",
					leave_date: "Dec.15.21-Dec.17.21",
					subjectLikeToTeach: "English reading,English writing",
					standard: "2nd standard",
					age: "5",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
			],
			leaveCount: null,
			assignedData:[]
		};
    },
    mutations: {
		updateLeaveApplication(state, { leaveItems }) {
			state.tableData = [];
			state.leaveCount = leaveItems.count;
			leaveItems.results.forEach(item => state.tableData.push(backendToFrontendFormatter(item)));
		},
		updateLeaveApplicantsDetails(state, { leaveItem }) {
			const index = state.tableData.findIndex(data => data.id == leaveItem.id);
			let leaveData = backendToFrontendFormatter(leaveItem);
			state.tableData.splice(index, 1, leaveData);		
		},
		updateLeave(state, { leaveItem }) {
			state.assignedData=leaveItem;			
		}
    },
	actions: {
		fetchLeaveApplications({commit},{status}) {
			console.log(status)
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.leaveApplicationURL.replace("{status}",status),
					method: "GET"
				}).then(leaveItems => {
					console.log('leave',leaveItems)
					commit('updateLeaveApplication', { leaveItems } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchLeaveApplicantsDetails({ commit }, leaveID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.leaveApplicantsDetailsURL.replace('{id}',leaveID),
					method: "GET"
				}).then(leaveItem => {
					commit('updateLeaveApplicantsDetails', { leaveItem } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		setLeaveApplicantsDetails({ commit },  {leaveData} ) {
			console.log("inside action".leaveData)
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.leaveApplicantsDetailsURL.replace("{id}", leaveData.id),
					data: frontendToBackendFormatter(leaveData),
					method: "PATCH"
				}).then(leaveItem => {
					commit("updateLeaveApplicantsDetailss", { leaveItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setTeacherReassign({ commit }, teacherData) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.teacherReassign.replace("{id}",teacherData.id),
					method: "POST",
					data:frontendToBackendFormatter(teacherData)
				}).then(leaveItem => {
					commit("updateLeave", { leaveItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		}
        
    },
   
};