import api from "@/api";
const backendToFrontendFormatter = originalData => {
    let data = JSON.parse(JSON.stringify(originalData));
    data.detailsloaded = false;
	if (data.student !== undefined) {
		data.detailsloaded = true;
    }
	return data;
};
const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	delete data.detailsloaded;
	
	return data;
};
export default {
    state: () => ({
        student_class: [],
        isLoaded: false,
        count:null
        
    }),
    mutations: {
        updateClassList(state, { classData }) {
            state.student_class = [];
            state.count = classData.count;
            classData.results.forEach(data => state.student_class.push(backendToFrontendFormatter(data)));
            state.isLoaded = true;    
        },
        updateClassDetails(state, { classItem }) {
            const index = state.student_class.findIndex(item => item.id== classItem.id);
            let data = backendToFrontendFormatter(classItem) 
            if (index != -1) {
                state.student_class.splice(index,1,data)
            } else {
                state.student_class.push(data);
            }
           
        }
        
       
    },
    actions: {
        fetchClassList({ commit }, { studentID,itemsPerPage,startIndex }) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.studentClassListURL.replace('{id}',studentID).replace('{itemsPerPage}',itemsPerPage).replace('{startIndex}',startIndex),
                    method:'GET'
                }).then(classData => {
                    commit('updateClassList', { classData })
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },
        fetchClassDetails({ commit }, { studentID, subjectID }) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.studentClassDetailsURL.replace('{student_id}',studentID).replace('{id}',subjectID),
                    method: 'GET',
                }).then(classItem => {
                    commit('updateClassDetails', { classItem })
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },
        setClassDetails({ commit }, { studentID, subjectData,subjectID }) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.studentClassDetailsURL.replace('{student_id}',studentID).replace('{id}',subjectID),
                    method: 'PATCH',
                    data:frontendToBackendFormatter(subjectData)
                }).then(classItem => {
                    commit('updateClassDetails', { classItem })
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },
       
    },
}
