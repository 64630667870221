import axios from 'axios';
import api from '@/api';

function getItem(itemName, defaultValue) {
	let localItem = localStorage.getItem(itemName);
	if (localItem != undefined) {
		return localItem;
	} else {
		return defaultValue;
	}
}


export default {
	state: () => ({
		username: getItem('username', 'Username'),
		token: getItem('admin-token'),
		userID: getItem('user-id', -1),
		profilePicture: getItem('profile-picture', 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'),
	}),
	mutations: { 
		login(state, { token, username, userID, refresh }) {
			console.log("login mutation- start")
			state.token = token;
			state.username = username;
			state.userID = userID;
			state.refresh = refresh
			console.log("login mutation- pass")
		},
		logout(state) {
			console.log("logout mutation start")
			state.token = undefined;
			state.username = undefined;
			state.userID = undefined;
			state.refresh = undefined;
			
			localStorage.removeItem('admin-token')
			localStorage.removeItem('username')
			localStorage.removeItem('user-id')
			localStorage.removeItem('refresh-token')

			delete axios.defaults.headers.common['Authorization'];
			console.log("logout mutation pass")
		},
		tokenStatusUpdate({tokenStatus}) {
			console.log("starting token status commit: ",tokenStatus)
		},
		tokenUpdate(state,{newToken}) {
			console.log("access token request action starts")
			state.token = newToken.access
			localStorage.removeItem('admin-token')
			localStorage.setItem('admin-token',newToken.access)
			console.log("access token updated successfully")
			console.log("new access token in local storage: ", state.token)
			console.log("access token request commit finished")

		}

	},
	actions: {
		login({ commit }, userData) {
			console.log(userData)
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.loginURL,
					data: userData,
					method: 'POST',
				}).then(loginData => {
					console.log(loginData)
					const token = loginData.token.access;
					const refresh = loginData.token.refresh;
					const username = loginData.user.username;
					const userID = loginData.user.id;
					
					localStorage.setItem('admin-token', token);
					localStorage.setItem('username', username);
					localStorage.setItem('user-id', userID);
					localStorage.setItem('refresh-token',refresh)
					console.log("sucess till now")

					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
					commit('login', { token, username, userID, refresh });
					commit('updateProfile', {userData:loginData.user});

					console.log("login checkpont")

					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		validateToken({commit},{access}){
			console.log("starting token validation action")
			let token = {
				token:access
			}
			console.log("this is token going to verify: ",token)
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.tokenVerifyURL,
					data: token,
					method: 'POST',
				}).then( tokenStatus=>{
					console.log("token validation action success with no error, status is ",tokenStatus)
					commit("tokenStatusUpdate",{tokenStatus:tokenStatus});
					console.log("finished token status commit")
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		requestToken({commit},{refresh_token}) {
			console.log("access token request action starts")
			let token = {
				refresh:refresh_token
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.requestNewTokenURL,
					data: token,
					method: 'POST',
				}).then( newToken=>{
					console.log("output token: ",newToken)
					commit("tokenUpdate",{newToken:newToken});
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		logout({ getters }) {
			return new Promise((resolve, reject) => {
				if (!getters.isAuthenticated) {
					resolve();
				} else {
					api.actionHandler({
						url: api.logoutURL,
						method: 'POST',
					}).then(() => {
						console.log("logout action-pass")
						resolve()
					})
					.catch(errMsg => reject(errMsg));
				}
			});
		}
	},
	
}
