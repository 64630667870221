import axios from "axios";

function actionHandler(payload) {
	return new Promise((resolve, reject) => {
		axios(payload)
			.then(response => {
				let resp = response.data;
				if (response.status >= 200 && response.status < 300) {
					resolve(resp.data);
				} else {
					reject(resp.message);
				}
			})
			.catch(err => {
				console.error(err);
				reject(getActionErrorMessage(err));
			});
	});
}

function getActionErrorMessage(err) {
	if (err.response?.data?.message) {
		return "Error: " + err.response.data.message;
	} else {
		return err;
	}
}

export default {
	baseURL: "https://app.roseapp.in/api_roseapp/",

	/* auth URLs */
	loginURL: "account/login/", // [POST]
	logoutURL: "account/logout/", // [POST]
	/* web_admin URLs */
    profileURL:'web_admin/admin_profile/',
    stmsListURL: "web_admin/stms/?search={search}&limit={itemsPerPage}&offset={startIndex}",
	newApplicantsURL: "web_admin/interview/",
	newApplicantDetailsURL: "/web_admin/interview/{id}/",
	individualStudentURL:'web_admin/student/{id}/',
	counselingListURL: 'web_admin/counseling/?completed={status}&limit={itemsPerPage}&offset={startIndex}',
	counselingDetailsURL: 'web_admin/counseling/{id}/',
	studentAssessmentURl:'web_admin/student/{id}/assessment/',
	demoClassRequestURL: "web_admin/demo_class/?completed={status}",
	demoClassRequestDetailsURL: "web_admin/demo_class/{id}/",
	teachersListURL: 'web_admin/tms/?search={search}&limit={itemsPerPage}&offset={startIndex}',
	teacherDetailsURL:'web_admin/teacher/{id}/',
	studentDashboardURL: 'web_admin/student_dashboard/',
	teacherDashboardURL: 'web_admin/teacher_dashboard/',
	leaveApplicationURL: 'web_admin/teacher_leaves/?status={status}',
	leaveApplicantsDetailsURL:'web_admin/teacher_leave/{id}/',
	courseListURL: "web_admin/subjects/?limit={itemsPerPage}&offset={startIndex}",
	courseDetailsURL: "web_admin/subject/{id}/",
	interviewCompletedListURL: "web_admin/interview_completed/?limit={itemsPerPage}&offset={startIndex}",
	interviewCompletedDetailsURL: "web_admin/interview_completed/{id}/",
	parentListURl: 'web_admin/parents/?limit={itemsPerPage}&offset={startIndex}',
	parentDetailsURl:'web_admin/parent/{id}',
	assignCourseURL: 'web_admin/student/{id}/course/',
	coursePlanURL: 'web_admin/course_plan/',
	transactionURL: 'web_admin/transaction/{id}/',
	assignTeacherURL: 'web_admin/course/{id}/assign_teacher/',
	teacherReassign: 'web_admin/leave/{id}/teacher_reassign/',
	studentClassListURL: 'web_admin/student/{id}/classes/?limit={itemsPerPage}&offset={startIndex}',
	studentClassDetailsURL: 'web_admin/student/{student_id}/class/{id}/',
	newStudentsListURL: 'web_admin/latest_students/?limit={itemsPerPage}&offset={startIndex}',
	newUserURL: 'web_admin/super_user/',
	newUserDetailsURL: 'web_admin/super_user/{id}/',
	notificationsURL: 'web_admin/notifications/',
	notificationURL:'web_admin/notification/{id}/',
	tokenVerifyURL:"account/api/token/verify/",
	requestNewTokenURL:"account/api/token/refresh/",
	studentAssessmentUpdateURl: "web_admin/student/{student_id}/assessment/{id}/",

	// core URLs
	stateListURL: 'core/states/?limit=30',
	districtURL: 'core/districts/?limit=700',

	//parent URLs
	totalTeacherListURL: "parent/teachers/",
	totalSubjectListURL: "parent/subjects/",
	subjectwiseTeachersURL:"parent/subject/{id}/teachers/",


	/* Misc handler functions */
	actionHandler
};
