import api from "@/api";
const backendToFrontendFormatter = originalData => {
    let data = JSON.parse(JSON.stringify(originalData));
	data.detailsloaded = false;		
	if (data.children != undefined) {
		data.detailsloaded=true
	}
	return data;
};
const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	delete data.detailsloaded;
	return data;
};
export default {
    state() {
        return {
            tableHeaders: ["id", "name", "address", "mobile"],
            parent_tableData: [],         
            isLoaded: false,
			parentCount: null,
			parentName:{},
			parent_data: {
				name: '',
				id: '',
				status:false
			}
			
        };
    },
    mutations: {
        updateParentList(state, { parentList }) {
            state.parent_tableData = [];
            state.parentCount = parentList.count;
            parentList.results.forEach(item => state.parent_tableData.push(backendToFrontendFormatter(item)));
            state.isLoaded = true;
        },
        updateParentDetails(state, { parentData }) {
            const index = state.parent_tableData.findIndex(parent => parent.id == parentData.id);
			let data = backendToFrontendFormatter(parentData);
            if (parentData.id != undefined) {
				state.parent_tableData.splice(index, 1, data);
				state.parent_data.name = data.parent_name;
				state.parent_data.id = data.parent_id;
				state.parent_data.status = true;
			} else {
				state.parent_tableData.push(data);
				
            }
        }
       
    },
    actions: {
        fetchParentList({ commit },{itemsPerPage,startIndex}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.parentListURl.replace('{itemsPerPage}',itemsPerPage).replace('{startIndex}',startIndex),
					method: "GET"
				}).then(parentList => {
					commit('updateParentList', { parentList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
        },
        fetchParentDetails({ commit },parentId) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.parentDetailsURl.replace('{id}',parentId),
					method: "GET"
				}).then(parentData => {
					commit('updateParentDetails', { parentData } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		setParentDetails({commit }, { parentData }) {
			let edit = parentData.id != undefined;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: edit ? api.parentDetailsURl.replace("{id}", parentData.id) :
					api.parentListURl,
					data: frontendToBackendFormatter(parentData),
					method:edit? "PATCH":'POST'
				}).then(parentData => {
					console.log('paremtData',parentData)
					commit("updateParentDetails", { parentData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},

    },
  
}