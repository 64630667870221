export default {
    
    notificationStatus: false,
		notificationsRead: true,
		notificationList: [
			{
				notificationTitle: 'Notification 1',
				linkTo: '#'
			},
			{
				notificationTitle: 'Notification 2',
				linkTo: '#'
			},
			{
				notificationTitle: 'Notification 3',
				linkTo: '#'
			}
		],
}