<template>
	<nav
		:class="
			toggle
				? 'navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white'
				: 'navbar navbar-vertical2  fixed-left navbar-expand-md navbar-light bg-white'
		"
		id="sidenav-main"
	>
		<div class="container-fluid">

			<div class="d-flex align-items-center collapseSidebar">
				<router-link v-if="toggle" class="navbar-brand pr-2" to="/stms">
					<img
						:src="logo"
						class="navbar-brand-img"
						alt="Company Logo"
					/>
				</router-link>
				<div
					class="d-flex justify-content-center align-items-center mt-4 ml-4"
				>
					<span v-if="!toggle" @click="toggle = true"
						><i class="fas fa-bars text-danger sidebarToggleBtn"></i
					></span>
				</div>
				<span v-if="toggle" @click="toggle = false"
					><i class="fas fa-times text-danger sidebarToggleBtn"></i
				></span>
			</div>

			<navbar-toggle-button @click.native="showSidebar">
				<span class="navbar-toggler-icon"></span>
			</navbar-toggle-button>

			<slot name="mobile-right">
				<ul class="nav align-items-center d-md-none ml-auto">
					<notification-panel></notification-panel>
					<base-dropdown class="nav-item" position="right">
						<a slot="title" class="nav-link" role="button">
							<div class="media align-items-center">
								<span class="avatar avatar-sm rounded-circle">
									<!-- <img alt="Image placeholder" :src="$store.state.loginData.profilePicture"> -->
									<img
										alt="Image placeholder"
										src="img/admin.jpg"
									/>
								</span>
							</div>
						</a>
						<mini-nav>
							
						</mini-nav>
					</base-dropdown>
				</ul>
			</slot>
			<slot></slot>
			<div
				v-show="$sidebar.showSidebar"
				class="navbar-collapse collapse show"
				id="sidenav-collapse-main"
			>
				<div class="navbar-collapse-header d-md-none">
					<div class="row">
						<div class="col-6 collapse-brand">
							<router-link to="/">
								<img :src="logo" />
							</router-link>
						</div>
						<div class="col-6 collapse-close">
							<navbar-toggle-button
								@click.native="closeSidebar"
							></navbar-toggle-button>
						</div>
					</div>
				</div>

				<ul class="navbar-nav rounded-right">
					<!-- This is where the main sidebar stuff gets in, See DashboardLayout.vue -->
					<slot name="links"> </slot>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import MiniNav from "@/layout/MiniNav";
import NotificationPanel from "@/layout/NotificationPanel";

export default {
	name: "sidebar",
	components: {
		NavbarToggleButton,
		MiniNav,
		NotificationPanel,
	},
	props: {
		logo: {
			type: String,
			default: "./img/icons/rose.png",
			description: "Sidebar app logo",
		},
		autoClose: {
			type: Boolean,
			default: true,
			description:
				"Whether sidebar should autoclose on mobile when clicking an item",
		},
	},
	data() {
		return {
			toggle: true,
		};
	},
	provide() {
		return {
			autoClose: this.autoClose,
			collapse: this.collapseToggle,
		};
	},
	methods: {
		collapseToggle() {
			if (!this.toggle) {
				return "nav-link-collapsed";
			} else {
				return "nav-link";
			}
		},
		closeSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		showSidebar() {
			this.$sidebar.displaySidebar(true);
		},
	},
	beforeDestroy() {
		if (this.$sidebar.showSidebar) {
			this.$sidebar.showSidebar = false;
		}
	},
};
</script>
<style scoped>
.sidebarToggleBtn {
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
.collapseSidebar{
	display:none !important;
}
}
</style>
