import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let teacher = JSON.parse(JSON.stringify(originalData));

	return teacher;
};

const frontendToBackendFormatter = originalData => {
	let applicant = JSON.parse(JSON.stringify(originalData));
	delete applicant.id
	return applicant;
};


export default {
    state() {
        return {
			tableHeaders: ["id", "name",'mobile','address'],
			tableData: [],
			isLoaded: false,
			count:null,
		};
    },
    mutations: {
        updateInterviewCompletedList(state, { interviewCompletedList }) {
			state.tableData = [];
			state.count = interviewCompletedList.count;
			interviewCompletedList.results.forEach(teacher => state.tableData.push(backendToFrontendFormatter(teacher)))
			state.isLoaded = true;
		},
		updateInterviewCompletedDetails(state, { interviewCompletedItem }) {
			const applicantIndex = state.tableData.findIndex(applicant => applicant.id == interviewCompletedItem.id)

			let application = backendToFrontendFormatter(interviewCompletedItem);
			if(applicantIndex != -1) {
				state.tableData.splice(applicantIndex, 1, application);
			}
		},
    },
    actions: {
		fetchInterviewCompletedList({ commit },{itemsPerPage,startIndex}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.interviewCompletedListURL.replace('{itemsPerPage}',itemsPerPage).replace('{startIndex}',startIndex),
					method: "GET"
				}).then(interviewCompletedList => {
					commit('updateInterviewCompletedList', { interviewCompletedList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchInterviewCompletedDetails({ commit }, applicantID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.interviewCompletedDetailsURL.replace("{id}", applicantID),
					method: "GET"
				}).then(interviewCompletedItem => {
					commit("updateInterviewCompletedDetails", { interviewCompletedItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setInterviewCompletedDetails({ commit },  {applicantData} ) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.interviewCompletedDetailsURL.replace("{id}", applicantData.id),
					data: frontendToBackendFormatter(applicantData),
					method: "PATCH"
				}).then(interviewCompletedItem => {
					//Not active bcs no need to update the list
					commit("updateInterviewCompletedDetailsNotActive", { interviewCompletedItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
        
    },
   
};