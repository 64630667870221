import api from "@/api";
const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	data.detailsloaded = false;
	if (data.mobile !== undefined) {
		data.detailsloaded = true;
	}
	return data;
};
const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	if(data.id!=undefined){
		delete data.id
	}
	return data;
}; 
export default {
	state() {
		return {
			tableHeaders: ["id", "name", "Counseling Date", "Counseling Time"],
			tableData: [],
			isLoaded: false,
			count:null
		};
	},
	mutations: {
		updateCounselingList(state, { counselingList }) {
			state.tableData = [];
			state.count = counselingList.count;
			counselingList.results.forEach(data => state.tableData.push(backendToFrontendFormatter(data)));
			state.isLoaded = true;			
		},
		updateCounselingDetails(state, { counselingData }) {
			const Index = state.tableData.findIndex(item => item.id == counselingData.id);
			let data = backendToFrontendFormatter(counselingData);
			if (counselingData.id != undefined) {
				state.tableData.splice(Index, 1, data);	
			} else {
				state.tableData.push(data);
			}
					
		}
	},
	actions: {
		fetchConselingList({ commit },{status,itemsPerPage,startIndex}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.counselingListURL.replace("{status}",status).replace('{itemsPerPage}',itemsPerPage).replace('{startIndex}',startIndex),
					method: "GET"
				}).then(counselingList => {
					commit('updateCounselingList', { counselingList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchConselingDetails({ commit },studentId) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.counselingDetailsURL.replace('{id}',studentId),
					method: "GET"
				}).then(counselingData => {
					commit('updateCounselingDetails', { counselingData })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		setCounselingDetails({ commit }, { counselingData }) {
			let edit = counselingData.id !== undefined;
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: edit ? api.counselingDetailsURL.replace('{id}',counselingData.id):api.counselingDetailsURL.replace('{id}/',''),
					method:edit ? "PATCH":'POST',
					data:frontendToBackendFormatter(counselingData)
				}).then(counselingData => {
					commit('updateCounselingDetails', { counselingData })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		}
	},
};
