import api from "@/api";
function backendToFrontendFormatter(originalData) {
	let data = JSON.parse(JSON.stringify(originalData));
	return data;
}
const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	console.log(data)
	return data;
}
export default {
	state: () => ({
		notificationsList:[],
		notifCount: null,
		isLoaded:false,
		// notificationData: [
		// 	{
		// 		head1: 'D',
		// 		head2: 'Demo class',
		// 		body: 'Adnan muhammed',
		// 		foot: '02-Jan 12.30-1.00',
		// 		color: 'color:#9B51E0;',
		// 		back_color: 'background-color:#9B51E0;',
		// 		classType: false

		// 	},
		// 	{
		// 		head1: 'D',
		// 		head2: 'Demo class',
		// 		body: 'Adnan muhammed',
		// 		foot: '02-Jan 12.30-1.00',
		// 		color: 'color:#219653;',
		// 		back_color: 'background-color:#219653;',
		// 		classType: false

		// 	},
		// 	{
		// 		head1: 'D',
		// 		head2: 'Demo class',
		// 		body: 'Adnan muhammed',
		// 		foot: '02-Jan 12.30-1.00',
		// 		color: 'color:#219653;',
		// 		back_color: 'background-color:#219653;',
		// 		classType: false

		// 	},
		// 	{
		// 		head1: 'D',
		// 		head2: 'Demo class',
		// 		body: 'Adnan muhammed',
		// 		foot: '02-Jan 12.30-1.00',
		// 		color: 'color:#F2994A;',
		// 		back_color: 'background-color:#F2994A;',
		// 		classType: false
			

		// 	},
		// 	{
		// 		head1: 'D',
		// 		head2: 'Demo class',
		// 		body: 'Adnan muhammed',
		// 		foot: '02-Jan 12.30-1.00',
		// 		color: 'color:#F2994A;',
		// 		back_color: 'background-color:#F2994A;',
		// 		classType: false
			

		// 	}
		// ]
	}),
		mutations: {
			updateNotifactions(state, { notificationsData }) {
				state.notificationsList = [];
				state.notifCount = notificationsData.count;
				notificationsData.results.forEach(item => state.notificationsList.push(backendToFrontendFormatter(item)));
				state.isLoaded = true;
				console.log("whole",state)
			},
			updateNotifaction(state, { notificationData }) {
				const index = state.notificationsList.findIndex(item => item.id == notificationData.id);
				let data = backendToFrontendFormatter(notificationData);
				if (index != -1) {
					state.notificationsList.splice(index, 1, data);
				} else {
					state.notificationsList.push(data);
				}
			}
		},
	actions: {	
		fetchNotificationsList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.notificationsURL,
					method: "GET",
				}).then(notificationsData => {
					console.log("notiflist",notificationsData)
					commit("updateNotifactions", { notificationsData } );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setNotification({ commit }, { notificationID, notificationStatus }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.notificationURL.replace('{id}',notificationID),
					method: "PATCH",
					data:frontendToBackendFormatter(notificationStatus)
				}).then(notificationData => {
					commit("updateNotifaction", { notificationData } );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});		
		}
	},
};
