import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let teacher = JSON.parse(JSON.stringify(originalData));

	return teacher;
};

const frontendToBackendFormatter = originalData => {
	let applicant = JSON.parse(JSON.stringify(originalData));
	delete applicant.id
	return applicant;
};

export default {
    state() {
        return {
			tableHeaders: ["id", "name"],
			fetchedData: [],
			tableData: [
				{
					id: 232389,
					name: "Sinan",
					subjectLikeToTeach: "English reading,English writing",
					interview_date: "Dec.15.2021-2.00-4.00",
					standard: "2nd std",
					age: "5",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
				{
					id: 232390,
					name: "Jabir",
					interview_date: "Dec.15.2021-2.00-4.00",
					subjectLikeToTeach: "English reading,English writing",
					standard: "3rd std",
					age: "6",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
				{
					id: 232391,
					name: "Rahul",
					interview_date: "Dec.15.2021-2.00-4.00",
					subjectLikeToTeach: "English reading,English writing",
					standard: "1st std",
					age: "4",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
				{
					id: 232392,
					name: "Keerthi",
					interview_date: "Dec.15.2021-2.00-4.00",
					subjectLikeToTeach: "English reading,English writing",
					standard: "3rd std",
					age: "6",
					address: "Pathinalunikathil House, Ezhikkara",
					mobile_number: 9876543245,
					whatsapp_number: 9807653421,
					join_date: "10-10-2021",
				},
			],
			isLoaded:false
		};
    },
    mutations: {
        updateNewApplicantsList(state, { newApplicantsList }) {
			console.log("here state",state)
			console.log("here list",newApplicantsList)
			state.fetchedData = [];
			newApplicantsList.results.forEach(teacher => state.fetchedData.push(backendToFrontendFormatter(teacher)))
			state.isLoaded = true;
		},
		updateNewApplicantDetails(state, { applicantItem }) {
			const applicantIndex = state.fetchedData.findIndex(applicant => applicant.id == applicantItem.id)

			let application = backendToFrontendFormatter(applicantItem);
			if(applicantIndex != -1) {
				state.fetchedData.splice(applicantIndex, 1, application);
			} else {
				state.fetchedData.push(application);
			}
			console.log(state.fetchedData)
		},
    },
    actions: {
		fetchNewApplicantsList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newApplicantsURL,
					method: "GET"
				}).then(newApplicantsList => {
					commit('updateNewApplicantsList', { newApplicantsList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchNewApplicantDetails({ commit }, applicantID) {
			console.log("hi now")
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newApplicantDetailsURL.replace("{id}", applicantID),
					method: "GET"
				}).then(applicantItem => {
					commit("updateNewApplicantDetails", { applicantItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setNewApplicantStatus({ commit },  {applicantData} ) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newApplicantDetailsURL.replace("{id}", applicantData.id),
					data: frontendToBackendFormatter(applicantData),
					method: "PATCH"
				}).then(applicantItem => {
					commit("updateCompletedInterviewsList", { applicantItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
        
    },
   
};