<template>
	<li class="nav-item p-1">
		<fade-transition :duration="200" origin="center top" mode="out-in">
			<router-link
				:to="link.path"
				@click.native="linkClick"
				:class="collapse()"
				:target="link.target"
				:href="link.path"
			>
				<template>
					<i :class="link.icon"></i>
					<span class="nav-link-text">{{ link.name }}</span>
				</template>
			</router-link>
		</fade-transition>
	</li>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
export default {
	name: "sidebar-item",
	components: {
		FadeTransition,
	},
	props: {
		link: {
			type: Object,
			default: () => {
				return {
					name: "",
					path: "",
					children: [],
				};
			},
			description:
				"Sidebar link. Can contain name, path, icon and other attributes. See examples for more info",
		},
	},
	inject: {
		autoClose: {
			default: true,
		},
		collapse: {},
	},
	data() {
		return {
			children: [],
			collapsed: true,
			collapseToggle: true,
		};
	},
	methods: {
		linkClick() {
			if (
				this.autoClose &&
				this.$sidebar &&
				this.$sidebar.showSidebar === true
			) {
				this.$sidebar.displaySidebar(false);
			}
		},
	},
};
</script>
