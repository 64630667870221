import Vue from "vue";
import Vuex from "vuex";

import accountData from "./AccountStore.js";
import notificationStore from "./NotificationStore.js";
import notificationData from './NotificationsStore.js';
import loginStore from "./LoginStore.js";
import profileData from "./ProfileStore.js";
import TmsData from './TmsStore/TmsStore.js';
import NewApplicantsData from './TmsStore/NewApplicantsStore';
import StmsData from './StmsStore/StmsStore';
import DemoClassData from './DemoClassStore/DemoClassStore';
import LeaveApplicationData from './TmsStore/LeavApplication';
import CoursesData from './CourseStore/CourseStore';
import NewStudentsData from './StmsStore/NewStudentsStore';
import CounsellersData from './CounsellerStore/CounsellersStore';
import ParentData from './ParentStore/ParentStore';
import DashboardData from './DashboardStore.js';
import LocationData from './LocationStore';
import InterviewCompletedData from './TmsStore/InterviewCompletedStore';
import StudentClassData from './StudentClassStore/ClassData.js';


Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		accountData,
		TmsData,
		StmsData,
		NewApplicantsData,
		DemoClassData,
		LeaveApplicationData,
		CoursesData,
		NewStudentsData,
		CounsellersData,
		ParentData,
		DashboardData,
		LocationData,
		InterviewCompletedData,
		profileData,
		StudentClassData,
		notificationData		
	},
	state: {
		notifications: notificationStore,
		loginData: loginStore,
		displayLoader: 0,
		
	},
	mutations: {
		displayLoader(state, displayLoader) {
			// Loader will be displayed if at least one action is running
			state.displayLoader += (displayLoader ? +1 : -1);
			if (state.displayLoader < 0) {
				state.displayLoader = 0;
			}
		},
		toggleLoader(state) {
			// Not recommended to use 
			state.displayLoader = (state.displayLoader == 0 ? 1 : 0);
		},
		notificationToggle(state, paylod) {
			state.notifications.notificationStatus = paylod;
			state.displayLoader = 0;
		},

		
	},
	actions: {
		notificationToggle(context, paylod) {
			context.commit('notificationToggle',paylod)
		},

	},
	strict: process.env.NODE_ENV !== 'production',
	getters: {
		isAuthenticated: state => state.accountData.token !==undefined,
		displayLoader:state=>state.displayLoader

	}
});

store.subscribeAction({
	before: () => {
		store.commit('displayLoader', true);
	},
	after: () => {
		store.commit('displayLoader', false);
	},
	error: () => {
		store.commit('displayLoader', false);
	}
});


export default store;
