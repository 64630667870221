import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store.js'

Vue.use(Router)

var availablePaths = [{
		path: '/',
		redirect: '/login',
		component: () => import('./views/Login'),
		children: [{
			path: '/login',
			name: 'login',
			productionAvailable: true
		}],
		productionAvailable: true
	},
	{
		path: '/',
		redirect: '/stms',
		component: () => import('./layout/DashboardLayout'),
		meta: { requiresAuth: true }, /* LOGIN LOGOUT IMPL MARKER */
		productionAvailable: true,
		children: [{
				path: 'stms',
				name: 'stms',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited. (Not anymore :p webpackChunkName is gone)
				component: () => import('./views/STMSDashboard/STMS')
		}, {
			path: 'stms/class-details/:studentID',
			name: 'student-class',
			component:()=>import('./views/ClassDashboard/StudentClass')
		},
			{
				path: 'stms/new-students',
				name: 'new-students',
				component:()=>import('./views/STMSDashboard/NewStudents')
			},
			{
				path: 'tms',
				name: 'tms',
				component: () => import('./views/TMSDashboard/TMS')
			},
			{
				path: 'parents',
				name: 'parents',
				component:()=>import('./views/ParentDashboard/Parents')
				
			},
			{
				path: 'new-applicants',
				name: 'new appliacants',
				component:()=> import('./views/TMSDashboard/NewApplicants')
				
			},
			{
				path: 'leave-applications',
				name: 'leave-applications',
				component: ()=> import('./views/TMSDashboard/LeaveRequests')
			},
			{
				path: 'counseling',
				name: 'counseling',
				component: () => import('./views/Counseling/Counseling')
			},
			{
				path: 'demo-class',
				name: 'demo-class',
				component: () => import('./views/DemoClass/DemoClass')
			},
			{
				path: 'subjects',
				name: 'subjects',
				component: () => import('./views/Courses/Courses')
			},
			{
				path: 'users',
				name: "users",
				component:()=>import('./views/Users')
			},
			{
				path: 'interview-completed',
				name: 'interview-completed',
				component: () => import('./views/TMSDashboard/InterviewCompleted')
			},
			{
				path: 'profile',
				component:()=>import('./views/Profile')
			}
		]
	},
	{
		path: '*',
		name: 'not found',
		component: () => import('./views/NotFound'),
		productionAvailable: false,
	},
]

const router = new Router({
	mode: 'history',
	base: '/',
	linkExactActiveClass: 'active',
	routes: process.env.NODE_ENV === 'production' ? availablePaths.filter((route) => route.productionAvailable) : availablePaths,
	scrollBehavior() {
		return { x: 0, y: 0, behavior: 'smooth' }
	},

});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// This route requires auth, check if authenticated
		// If not, redirect to login page
		if (!store.getters.isAuthenticated) {
			next({
				// path: '/login',
				query: {
					redirect: to.fullPath
				}
			})
		}
			else {
			next();
		}
		// next()
	} else {
		// LOGIN LOGOUT IMPL MARKER
		if (to.name == 'login' && store.getters.isAuthenticated) {
			// Redirect to the Dashboard as the user attempted to view the
			// login page while being authenticated already
			next({
				path: '/stms',
			});
		} else {
			next();
		} 
		
		// next();
	}
})

export default router;
