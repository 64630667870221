
import api from "@/api";
import axios from 'axios';

const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	data.detailsloaded = false;
	if (data.mobile != undefined) {
		data.detailsloaded = true;
	}
	return data;
};
const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	delete data.detailsloaded;	
	return data;
};

export default {
	state() {
		return {
			tableHeaders: ["id", "name", "payment status"],
			tableData:[],
			assessmentData: [],
			coursePlan: [],
			course:[],
			isLoaded:false,
			stmsCount: null,
			assessmentCount: null,
			transaction:{}
		};
	},
	mutations: {
		updateSTMSList(state, { stmsList }) {
			state.tableData = [];
			state.stmsCount = stmsList.count;
			stmsList.results.forEach(stmsList => state.tableData.push(backendToFrontendFormatter(stmsList)))
			state.isLoaded = true;
		},
		updateStudent(state, { studentItem }) {
			const Index = state.tableData.findIndex(student => student.id == studentItem.id);
			let studentData = backendToFrontendFormatter(studentItem);
			if (Index !=-1) {
				state.tableData.splice(Index, 1, studentData);
				
			} else {
				state.tableData.push(studentData);
			}
		},
		updateStudentAssessment(state, { assessment }) {
			let data = assessment.results
			state.assessmentData = data;
			state.assessmentCount = assessment.count;
		},
		updateSingleStudentAssessment(state,{ assessment }) {
			let index = state.assessmentData.findIndex(item=>item.id == assessment.id)
			state.assessmentData[index].body = assessment.body


		},
		updateCoursePlan(state, { planList }) {
			state.coursePlan=[]
			planList.results.forEach(item => state.coursePlan.push(backendToFrontendFormatter(item)));
		},
		updateCourse(state, { courseData }) {
			state.course.push(backendToFrontendFormatter(courseData));
		},
		setTransaction(state, { item }) {
			state.transaction = item;
		}
	},		
	actions: {
		fetchSTMSList({ commit }, { searchTerm,itemsPerPage,startIndex }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.stmsListURL.replace('{search}',searchTerm).replace('{itemsPerPage}',itemsPerPage).replace('{startIndex}',startIndex),
					method: "GET"
				}).then(stmsList => {
					commit('updateSTMSList', { stmsList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchStudentDetails({ commit },studentID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.individualStudentURL.replace('{id}',studentID),
					method: "GET"
				}).then(studentItem => {
					commit('updateStudent', { studentItem } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		// setStudentDetails({ commit }, { studentData }) {
		// 	let edit = studentData.id != undefined;
			// return new Promise((resolve, reject) => {
			// 	api.actionHandler({
			// 		url: edit ? api.individualStudentURL.replace("{id}", studentData.id) :
			// 		api.individualStudentURL.replace("{id}/",''),
			// 		data: frontendToBackendFormatter(studentData),
			// 		method:edit? "PATCH":'POST'
			// 	}).then(studentItem => {
			// 		commit("updateStudent", { studentItem });
			// 		resolve();
			// 	}).catch(errMsg => reject(errMsg));
			// });
		// },
		setStudentDetails({ commit }, { studentData }) {
			let edit = studentData.id != undefined;
			if(!edit){
				return new Promise((resolve, reject) => {
					axios.post(api.individualStudentURL.replace("{id}/",''),
						studentData,{
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						}
					).then(studentItem => {
						commit("updateStudent", { studentItem });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
			}
			else{
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url: api.individualStudentURL.replace("{id}", studentData.id),
						data: frontendToBackendFormatter(studentData),
						method:"PATCH"
					}).then(studentItem => {
						commit("updateStudent", { studentItem });
						resolve();
					}).catch(errMsg => reject(errMsg));
				});
			}
		},
		fetchStudentAssessment({ commit },{studentID}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.studentAssessmentURl.replace('{id}',studentID),
					method: "GET"
				}).then(assessment => {
					commit('updateStudentAssessment', { assessment } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		setStudentAssessment({ commit }, { studentID, assessData }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.studentAssessmentURl.replace('{id}',studentID),
					method: "POST",
					data:frontendToBackendFormatter(assessData)
				}).then(assessment => {
					console.log('set',assessment)
					commit('updateStudentAssessment', { assessment } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		updateStudentAssessment({ commit }, { studentID, assessmentID, assessData }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.studentAssessmentUpdateURl.replace('{student_id}',studentID).replace('{id}',assessmentID),
					method: "PATCH",
					data:frontendToBackendFormatter(assessData)
				}).then(assessment => {
					console.log('set',assessment)
					commit('updateSingleStudentAssessment', { assessment } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchCoursePlan({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.coursePlanURL,
					method: "GET"
				}).then(planList => {
					commit('updateCoursePlan', { planList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		setCoursePlan({ commit },{ courseData, studentID }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.assignCourseURL.replace('{id}',studentID),
					method: "POST",
					data:frontendToBackendFormatter(courseData)
				}).then(courseData => {
					commit('updateCourse', { courseData } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
			
		},
		setTransaction({ commit }, { transactionID, data } ) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.transactionURL.replace('{id}',transactionID),
					method: "PATCH",
					data:frontendToBackendFormatter(data)
				}).then(Item => {
					commit('setTransaction', { Item } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},

	},
};