import api from "@/api";
const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
    state: () => ({
        states: [],
        districts: [],
        isLoaded:false
    }),
    mutations: {
        
        updateStateList(state, { stateData }) {
            state.states=[]
            stateData.results.forEach(stateItem => state.states.push(backendToFrontendFormatter(stateItem)));
            state.isLoaded = true;

        },
        updateDistrictList(state, { districtData,stateID }) {
            let data = districtData.results.filter(districtItem => districtItem.state == stateID);
            state.districts=backendToFrontendFormatter(data)
            
        },
    },
    actions: {
        fetchStateList({ commit }) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.stateListURL,
                    method:'GET'
                }).then(stateData => {

                    commit('updateStateList', { stateData })
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },
        fetchDistrictList({ commit },stateID) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.districtURL,
                    method:'GET'
                }).then(districtData => {
                    commit('updateDistrictList', { districtData,stateID })
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },
       
    },
}
