import api from "@/api";

const backendToFrontendFormatter = originalData => {
	let demoClass = JSON.parse(JSON.stringify(originalData));
	demoClass.detailsloaded = false;
	if (demoClass.student_id != undefined) {
		demoClass.detailsloaded = true;
	}
	return demoClass;
};

const frontendToBackendFormatter = originalData => {
	let student = JSON.parse(JSON.stringify(originalData));
	delete student.detailsloaded
	delete student.id
	student.teacher = student.teacher.id
	return student;
};

export default {
	state() {
		return {
			tableHeader: ["id", "name", "date", "time"],
			tableData: [],
			fetchedData: [],
			isLoaded: false,
		};
	},
	mutations: {
		updateDemoClassRequests(state, { demoClassList }) {
			state.fetchedData = [];
			demoClassList.results.forEach(demoClass => state.fetchedData.push(backendToFrontendFormatter(demoClass)))
			state.isLoaded = true;
		},
		updateDemoClassRequestDetails(state, demoClassDetails ) {
			const demoClassIndex = state.fetchedData.findIndex(demoClass => demoClass.id == demoClassDetails.id)
			let demoClass = backendToFrontendFormatter(demoClassDetails);
			console.log(demoClassIndex)
			if (demoClassIndex != -1) {				
				state.fetchedData.splice(demoClassIndex, 1, demoClass);
			} else {
				state.fetchedData.push(demoClass);
			}

		},
	},
	actions: {
		fetchDemoClassRequests({ commit },{status}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.demoClassRequestURL.replace("{status}",status),
					method: "GET"
				}).then(demoClassList => {
					commit('updateDemoClassRequests', { demoClassList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchDemoClassRequestDetails({ commit }, demoClassID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.demoClassRequestDetailsURL.replace("{id}", demoClassID),
					method: "GET"
				}).then(demoClassDetails => {
					commit("updateDemoClassRequestDetails", demoClassDetails )
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setDemoClassRequestDetails({ commit }, { studentData }) {
			console.log(studentData)
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.demoClassRequestDetailsURL.replace("{id}", studentData.id),
					data: frontendToBackendFormatter(studentData),
					method: "PATCH"
				}).then(demoClassDetails => {
					//Not active bcs no need to update the list
					commit("updateDemoClassRequestDetailsNotActive", { demoClassDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		
	},
};