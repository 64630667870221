import api from "@/api";
function backendToFrontendFormatter(originalData) {
	let profileData = JSON.parse(JSON.stringify(originalData));
	profileData.detailsloaded = false;
	if (profileData.mobile !== undefined) {
		profileData.detailsloaded = true;
	}
	return profileData;
}
const frontendToBackendFormatter = originalData => {
	let profileData = JSON.parse(JSON.stringify(originalData));
	console.log(profileData)
	if (profileData.district_and_state != undefined) {
		profileData.district = profileData.district_and_state.district;
		delete profileData.district_and_state;
	}
	delete profileData.detailsloaded;
	return profileData;
};  

export default {
	state: () => ({
		data: {},
		newUserList: [],
		userCount:null
	}),
	mutations: {
		updateProfile(state, { userData }) {
			let user = backendToFrontendFormatter(userData);
			state.data = user;
		},
		updateNewUser(state, { userItem }) {
			state.newUserList=[]
			userItem.results.forEach(item => state.newUserList.push(backendToFrontendFormatter(item)));
			state.userCount = userItem.count;
		},
		updateUser(state, { userItem }) {
			const index = state.newUserList.findIndex(item => item.id == userItem.id);
			let data = backendToFrontendFormatter(userItem);
			if (index != -1) {
				state.newUserList.splice(index, 1, data);
			} else {
				state.newUserList.push(data)
			}
		},
		deleteUser(state, index) {
			state.newUserList.splice(index,1)
		}
		
	},
	actions: { 
		fetchAdminProfile({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.profileURL,
					method: "GET",
				}).then(userData => {
					console.log(userData)
					commit("updateProfile", { userData } );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		setAdminProfile({ commit, }, { userData }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.profileURL,
					data: frontendToBackendFormatter(userData),
					method: "PATCH"
				}).then(userData => {
					commit("updateProfile", { userData });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		fetchNewUsersList({commit}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newUserURL,
					method: "GET",
				}).then(userItem => {
					commit("updateNewUser", { userItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},

		setNewUser({ commit, }, { Data }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newUserURL,
					data: frontendToBackendFormatter(Data),
					method: "POST"
				}).then(userItem => {
					commit("updateUser", { userItem });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		fetchnewUserDetails({ commit },userID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newUserDetailsURL.replace('{id}',userID),
					method: "GET",
				}).then(userItem => {
					commit("updateUser", { userItem } );
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		deleteUser({ commit }, { id, index }) {
			let userID = id;
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url: api.newUserDetailsURL.replace('{id}',userID),
						method: 'DELETE',
					}).then(() => {
						commit('deleteUser', index);
						resolve();
					}).catch(errMsg => reject(errMsg));
				});
			},
	},
	
}