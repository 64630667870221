import api from "@/api";
import axios from 'axios';
const backendToFrontendFormatter = originalData => {
	let demoClass = JSON.parse(JSON.stringify(originalData));
	return demoClass;
};

const frontendToBackendFormatter = originalData => {
	let course = JSON.parse(JSON.stringify(originalData));			
	return course;
};


export default {
    state() {
        return {
			tableHeaders: ["id", "name", "total students", "total teachers"],
			fetchedData: [],
			isLoaded: false,
			teacherData: {},
			subjectsCount: null,
			totalSubjectList:[]
		};
    },
    mutations: {
        updateCourseList(state, { courseList }) {
			state.fetchedData = [];
			state.subjectsCount = courseList.count;
			courseList.results.forEach(subject => state.fetchedData.push(backendToFrontendFormatter(subject)))
			state.isLoaded = true;
		},
		updateCourseDetails(state, { courseDetails }) {
			const courseIndex = state.fetchedData.findIndex(subject => subject.id == courseDetails.id)
			let course = backendToFrontendFormatter(courseDetails);
			if(courseIndex != -1) {
				state.fetchedData.splice(courseIndex, 1, course);
			} 
		},
		updateTotalSubjectList(state, { subjectList }) {
			state.totalSubjectList = [];
			subjectList.results.forEach(subject => state.totalSubjectList.push(backendToFrontendFormatter(subject)))

		}
    },
	actions: {
		fetchCourseList({ commit }, { itemsPerPage, startIndex }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.courseListURL.replace('{itemsPerPage}', itemsPerPage).replace('{startIndex}', startIndex),
					method: "GET"
				}).then(courseList => {
					commit('updateCourseList', { courseList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
		fetchCourseDetails({ commit }, courseID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.courseDetailsURL.replace("{id}", courseID),
					method: "GET"
				}).then(courseDetails => {
					commit("updateCourseDetails", { courseDetails });
					resolve();
				}).catch(errMsg => reject(errMsg));
			});
		},
		// setCourseDetails({ commit }, { courseData }) {
		// 	let editCourse = courseData.id != undefined;
		// 	return new Promise((resolve, reject) => {
		// 		api.actionHandler({
		// 			url: editCourse ? api.courseDetailsURL.replace("{id}", courseData.id) :
		// 				api.courseListURL,
		// 			data: frontendToBackendFormatter(courseData),
		// 			method: editCourse ? "PATCH" : "POST"
		// 		}).then(courseDetails => {
		// 			commit("updateCourseDetails", { courseDetails });
		// 			resolve();
		// 		}).catch(errMsg => reject(errMsg));
		// 	});
		// },
		setCourseDetails({ commit }, { courseData }) {
			let edit = courseData.id != undefined;
			if(edit){
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url: api.courseDetailsURL.replace("{id}", courseData.id),
						data: frontendToBackendFormatter(courseData),
						method:"PATCH"
					}).then(courseDetails => {
						commit("updateCourseDetails", { courseDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				});
			}
			else {
				return new Promise((resolve, reject) => {
					axios({
						url: api.courseListURL,
						method: 'POST',					
						data: courseData,
						headers:{'Content-Type': 'multipart/form-data'}
					}).then(courseDetails => {
						commit("updateCourseDetails", { courseDetails });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
				
			}
		},
		fetchTotalSubjectList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.totalSubjectListURL,
					method: "GET"
				}).then(subjectList => {
					commit('updateTotalSubjectList', { subjectList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
	}

   
};