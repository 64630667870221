import api from "@/api";
const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	return data;
};
export default {
    state: () => ({
        stmsCardData: [
            {
                count: 7,
                head: "New students",
                body: " New 7 students joined",
                routeId: 33,
            },
            {
                count: 12,
                head: "Demo Class Request",
                body: "12 students waiting",
                routeId: 34,
            },
            {
                count: 14,
                head: "Payment Pending",
                body: "yet to be updated",
                routeId: 35,
            },
        ],
        tmsCardData: [
            {
                count: 26,
                head: "New Applicants",
                body: "12 Teachers waiting",
                color: "red",
            },
            {
                count: 26,
                head: "Leave Applications",
                body: "Change teachers",
                color: "blue",
            },
            {
                count: 10,
                head: "Interview Completed",
                body: "New teachers",
                color: "green",
            }
        ],
        newApplicantsCardData: [
            {
                count:12,
                head:'Total Teachers',
                body:'For 12 subjects to teach',
                color:'red'
                
            },
            {
                count:26,
                head:'Leave Applications',
                body:'Change teachers',
                color:'blue'

            },
            {
                count: 10,
                head: "Interview Completed",
                body: "New teachers",
                color: "green",
            }
        ],
        leaveRequestCardData: [
            {
                count:12,
                head:'Total Teachers',
                body:'For 12 subjects to teach',
                color:'red'
                
            },
            {
                count:26,
                head:'New Applicants ',
                body:'12 Teachers waiting',
                color:'blue'

            },
            {
                count: 10,
                head: "Interview Completed",
                body: "New teachers",
                color: "green",
            }
        ],
        interviewCompletedCardData: [
            {
                count:12,
                head:'Total Teachers',
                body:'For 12 subjects to teach',
                color:'red'
                
            },
            {
                count:26,
                head:'New Applicants ',
                body:'12 Teachers waiting',
                color:'blue'

            },
            {
                count: 10,
                head: "Leave Applications",
                body: "Change teachers",
                color: "green",
            }
        ],
        demclassCardData: [
            {
                count: 26,
                head: "Payment Pending",
                body: "yet to be updated",
                className: "blue",
            },
            {
                count: 12,
                head: "Demo Class Request",
                body: "12 students waiting",
            },
        ],
        SubjectCardData: [
            {
                count: 12,
                head: "Total students",
                body: "Total  7 students",
                className: "blue",
            },
            {
                count:12,
                head:'Total Teachers',
                body:'For 12 subjects to teach',
                color:'red'
            
        },
        ],
        total_students: null,
        total_teachers: null,
        new_students: null,
        new_applicants_count: null,
        leaveRequestsCount:null
    }),
    mutations: {
        updateStudentDashboard(state, { studentData }) {
            let data = backendToFrontendFormatter(studentData);
            state.stmsCardData[1].count = data.demo_class_requests;
            state.stmsCardData[1].body = `${data.demo_class_requests} students waiting`;
            state.demclassCardData[1].count = data.demo_class_requests;
            state.demclassCardData[1].body = `${data.demo_class_requests} students waiting`;
            state.demclassCardData[0].count = data.payment_pending;
            state.total_students = data.total_students;
            state.SubjectCardData[0].count = data.total_students;
            state.SubjectCardData[0].body = `Total ${data.total_students} students`;
            state.stmsCardData[0].count = data.latest_students;
            state.stmsCardData[0].body = `${data.latest_students} students joined`;
            state.stmsCardData[2].count = data.payment_pending;
            state.new_students = data.latest_students;
            
        },
        updateTeacherDashboard(state, { teacherData }) {
            let data = backendToFrontendFormatter(teacherData);
            state.newApplicantsCardData[0].count = data.total_teachers;
            state.leaveRequestCardData[0].count = data.total_teachers;
            state.interviewCompletedCardData[0].count = data.total_teachers;
            state.SubjectCardData[1].count = data.total_teachers;
            state.SubjectCardData[1].body=`Total ${data.total_teachers} teachers`
            state.total_teachers = data.total_teachers;
        },
        updateNewApplicantCardCount(state, { item }) {
            state.new_applicants_count = item.count;
            state.tmsCardData[0].count = item.count;
            state.tmsCardData[0].body = `${item.count} Teachers waiting`;
            state.leaveRequestCardData[1].count = item.count;
            state.leaveRequestCardData[1].body = `${item.count} Teachers waiting`;
            state.interviewCompletedCardData[1].count = item.count;
            state.interviewCompletedCardData[1].body = `${item.count} Teachers waiting`;
        },
        updateLeaveApplicationCount(state, { data }) {
            state.leaveRequestsCount = data.count;
            state.tmsCardData[1].count = data.count;
            state.newApplicantsCardData[1].count = data.count;
            state.interviewCompletedCardData[2].count = data.count;
        },
        updateInterviewCompletedCount(state, { data }) {
            state.tmsCardData[2].count = data.count;
            state.leaveRequestCardData[2].count = data.count;
            state.newApplicantsCardData[2].count = data.count;
        }
    },
    actions: {
        fetchStudentDashboardData({ commit }) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.studentDashboardURL,
                    method:'GET'
                }).then(studentData => {
                    commit('updateStudentDashboard', { studentData })
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },
        fetchTeacherDashboardData({ commit }) {
            return new Promise((resolve, reject) => { 
                api.actionHandler({
                    url: api.teacherDashboardURL,
                    method:'GET'
                }).then(teacherData => {
                    commit('updateTeacherDashboard', { teacherData })
                    resolve();
                }).catch(errMsg => reject(errMsg))
            })
           
        },
        fetchNewAppliacantCount({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newApplicantsURL,
					method: "GET"
                }).then(item => {
					commit('updateNewApplicantCardCount', { item })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
        },
        LeaveApplicationsCount({commit},{status}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.leaveApplicationURL.replace("{status}",status),
					method: "GET"
                }).then(data => {
					commit('updateLeaveApplicationCount', { data } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
        InterviewCompletedCount({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.interviewCompletedListURL,
					method: "GET"
				}).then(data => {
					commit('updateInterviewCompletedCount', { data })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		},
    },
    
}