import api from "@/api";
import axios from 'axios';
const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	data.detailsloaded = false;
	if (data.meet_url !== undefined) {
		data.detailsloaded = true;
	}
	return data;
};
const frontendToBackendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	delete data.detailsloaded;
	data.subjects = data.subjects.map(item => item.id)
	return data;
}; 
 
export default {
    state() {
        return {
			tableHeaders: ["id", "name"],
			tableData: [],
			teacherCount: null,
			isLoaded: false,
			teacherData: [],
			totalTeachersList: [],
			subwiseTecherList:[]
		};
    },
	mutations: {
		updateTeacher(state, { teacherItem }) {
			state.tableData = [];
			state.teacherCount = teacherItem.count;
			teacherItem.results.forEach(teacher => state.tableData.push(backendToFrontendFormatter(teacher)));
			state.isLoaded=true;
		},
		updateTeacherDetails(state, { teacherData }) {
			const index = state.tableData.findIndex(item => item.id == teacherData.id);
			let data = backendToFrontendFormatter(teacherData);
			if (teacherData.id !==undefined) {
				state.tableData.splice(index, 1 ,data)
			} else {
				state.tableData.push(data);
			}							
		},
		updateTeacherList(state, { teacherList }) {
			state.totalTeachersList = [];
			teacherList.results.forEach(teacher => state.totalTeachersList.push(backendToFrontendFormatter(teacher)));
		},
		updateSubwiseTeacherList(state, { list }){
			state.subwiseTecherList = [];
			list.results.forEach(teacher => state.subwiseTecherList.push(backendToFrontendFormatter(teacher)));
		}
    },
	actions: {
		fetchTeachersList({ commit },{ searchTerm,itemsPerPage,startIndex }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.teachersListURL.replace('{search}',searchTerm).replace('{itemsPerPage}',itemsPerPage).replace('{startIndex}',startIndex),
					method: "GET"
				}).then(teacherItem => {
					commit('updateTeacher', { teacherItem } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})			
		},
		fetchTeacherDetails({ commit },teacherId) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.teacherDetailsURL.replace('{id}',teacherId),
					method: "GET"
				}).then(teacherData => {
					commit('updateTeacherDetails', { teacherData } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})			
		},
		setTeacherDetails({ commit }, { teacherData }) {
			let edit = teacherData.id != undefined;
			if(edit){
				return new Promise((resolve, reject) => {
					api.actionHandler({
						url: api.teacherDetailsURL.replace("{id}", teacherData.id),
						data: frontendToBackendFormatter(teacherData),
						method:"PATCH"
					}).then(teacherData => {
						commit("updateTeacherDetails", { teacherData });
						resolve();
					}).catch(errMsg => reject(errMsg));
				});
			}
			else {
				return new Promise((resolve, reject) => {
					axios({
						url: api.teacherDetailsURL.replace("{id}/", ''),
						method: 'POST',					
						data: teacherData,
						headers:{'Content-Type': 'multipart/form-data'}
					}).then(teacherData => {
						commit("updateTeacherDetails", { teacherData });
						resolve();
					}).catch(errMsg => reject(errMsg));
				})
				
			}
			
		},
		fetchTotalTeachersList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.totalTeacherListURL,
					method: "GET"
				}).then(teacherList => {
					console.log(teacherList);
					commit('updateTeacherList', { teacherList } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})			
		},
		fetchSubwiseTeachersList({ commit },courseID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.subjectwiseTeachersURL.replace("{id}",courseID),
					method: "GET"
				}).then(list => {
					commit('updateSubwiseTeacherList', { list } )
					resolve();
				}).catch(errMsg => reject(errMsg))
			})			
		},
        
    },
   
};