import api from "@/api";
const backendToFrontendFormatter = originalData => {
	let data = JSON.parse(JSON.stringify(originalData));
	data.detailsloaded = false;
	if (data.mobile !== undefined) {
		data.detailsloaded = true;
	}
	return data;
};
export default {
    state() {
        return {
			tableHeaders: ["id", "name", "teacher", "status"],
			tableData: [],
			isLoaded: false,
		};
    
    },
	mutations: {
		updateNewStudentsList(state, { studentList }) {
			state.tableData = [];
			studentList.results.forEach(student => state.tableData.push(backendToFrontendFormatter(student)));
			state.isLoaded = true;
		}
        
    },
	actions: {
		fetchNewStudentsList({ commit },{itemsPerPage,startIndex }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.newStudentsListURL.replace('{itemsPerPage}',itemsPerPage).replace('{startIndex}',startIndex),
					method: "GET"
				}).then(studentList => {
					commit('updateNewStudentsList', { studentList })
					resolve();
				}).catch(errMsg => reject(errMsg))
			})
		}
        
    },
};